// Generated by Framer (c93245f)

import { addFonts, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["rnCttzqja"];

const serializationHash = "framer-BkTIN"

const variantClassNames = {rnCttzqja: "framer-v-mz6jc5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "rnCttzqja", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://squads.so/"}><Image {...restProps} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 1080, intrinsicWidth: 1080, pixelHeight: 1080, pixelWidth: 1080, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/oyHawYeNL42hQc2Fjb15RrGCGg.png", srcSet: "https://framerusercontent.com/images/oyHawYeNL42hQc2Fjb15RrGCGg.png?scale-down-to=512 512w,https://framerusercontent.com/images/oyHawYeNL42hQc2Fjb15RrGCGg.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/oyHawYeNL42hQc2Fjb15RrGCGg.png 1080w"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-mz6jc5", className, classNames)} framer-1dcmjw3`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rnCttzqja"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BkTIN.framer-1dcmjw3, .framer-BkTIN .framer-1dcmjw3 { display: block; }", ".framer-BkTIN.framer-mz6jc5 { height: 173px; position: relative; text-decoration: none; width: 181px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 173
 * @framerIntrinsicWidth 181
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerl4vVIZTur: React.ComponentType<Props> = withCSS(Component, css, "framer-BkTIN") as typeof Component;
export default Framerl4vVIZTur;

Framerl4vVIZTur.displayName = "squads";

Framerl4vVIZTur.defaultProps = {height: 173, width: 181};

addFonts(Framerl4vVIZTur, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})